<template>
  <div class="container">
    <b-steps
      size="is-medium"
      v-model="activeStep"
      :animated="isAnimated"
      :has-navigation="hasNavigation"
    >
      <b-step-item label="Caricamento" icon="account-key">
        <LoadExcel v-on:loaded="activeStep = 1; $refs.categorizza.resetDatePeriodInterval()" />
      </b-step-item>

      <b-step-item label="Categorizzazione" icon="account">
        <b-message
          title="Supporto"
          type="is-info"
          has-icon
          aria-close-label="Chiudi"
          size="is-small"
        >
          <p class="title is-6">
            Hai qualche suggerimento? Possiamo migliorare la categorizzazione? Inviaci i tuoi suggerimenti ad
            <a
              class="mail"
              href="mailto:assistenza@comesto.biz"
              title="Contattaci"
            >assistenza@comesto.biz</a>!
          </p>
        </b-message>
        <b-button type="is-warning" @click="activeStep = 2">Vai al prossimo step</b-button>
        <Categorizza :readOnly="false" ref="categorizza" />
      </b-step-item>

      <b-step-item label="Contanti" icon="account">
        <section class="section">
          <p class="title">Quanti contanti hai?</p>
          <Contanti labelDoneButton="Vai al prossimo step" v-on:done="activeStep = 3" />
        </section>
      </b-step-item>

      <b-step-item label="Debiti/Crediti" icon="account">
        <DebitiCrediti labelDoneButton="Vai al prossimo step" v-on:done="activeStep = 4" />
      </b-step-item>

      <b-step-item label="Altro" icon="account">
        <Altro labelDoneButton="Completa la procedura guidata" v-on:done="$router.push('/app')" />
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import LoadExcel from "@/components/import/LoadExcel.vue";
import Categorizza from "@/components/import/Categorizza.vue";
import Contanti from "@/components/import/Contanti.vue";
import DebitiCrediti from "@/components/import/DebitiCrediti.vue";
import Altro from "@/components/import/Altro.vue";

export default {
  name: "inserisciDati",
  components: {
    LoadExcel,
    Categorizza,
    Contanti,
    DebitiCrediti,
    Altro
  },
  data() {
    return {
      activeStep: 0,
      isAnimated: false,
      hasNavigation: false,
      dropFiles: null
    };
  }
};
</script>